<template>
    <div class="event">
        <div class="head">
            <div class="back" @click="back()">
                <back :size="'20px'"/>
            </div>
            <div class="head_title">Event</div>
        </div>
        <div class="main">
            <div class="image">
                <img :src="event.image"/>
            </div>
            <div class="info">
                <div class="f">
                    <div class="label">Title</div>
                    <div class="value">{{ event.title }}</div>
                </div>
                <div class="f">
                    <div class="label">Description</div>
                    <div class="value">{{ event.description }}</div>
                </div>
                <div class="f">
                    <div class="label">Location</div>
                    <div class="value">{{ event.location }}</div>
                </div>
                <div class="f">
                    <div class="label">Date</div>
                    <div class="value">{{ date }}</div>
                </div>
                <div class="f">
                    <div class="label">Amount</div>
                    <div class="value" v-if="gender == 'Male'">₦5,000</div>
                    <div class="value" v-if="gender == 'Female'">₦3,000</div>
                </div>
                <div class="f" v-if="!paid">
                    <div class="label">Whasapp Number</div>
                    <input type="text" placeholder="Enter your whatsapp number" v-model="whatsapp">
                </div>
                <div v-if="paid">
                    <div class="label">Payment</div>
                    <div class="value">
                        Your payment was succesful, the qr code for the event will be generated soon
                    </div>
                </div>
                <div v-else>
                    <loading :height="'39px'" v-if="load"/>
                    <div class="btn" v-else @click="payViaService()">Pay</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment'
import back from '../../icons/back.vue'

import loading from '../../components/loading.vue'

export default {
    components: {
        back, loading
    },
    data() {
        return {
            paid: false,

            load: false,
            event: {},
            date: '',
            gender: this.$root.$data.gender,
            whatsapp: '',

            paymentData: {
                tx_ref: this.generateReference(),
                amount: "0",
                currency: "NGN",
                payment_options: "banktransfer,card,ussd",
                redirect_url: "",
                meta: {
                    username: this.$root.$data.username,
                    gender: this.$root.$data.gender
                },
                customer: {
                    name: this.$root.$data.firstname + ' ' + this.$root.$data.lastname,
                    email: this.$root.$data.email
                },
                customizations: {
                    title: "Ikotun Connect Payment",
                    description: "Pool Party Payment",
                    logo: "https://ikotunconnect.com.ng/api/images/ikotun_logo_2.png"
                },
                callback: this.makePaymentCallback,
                onclose: this.closedPaymentModal
            }
        }
    },
    mounted() {
        this.event = JSON.parse(localStorage.getItem('event'))
        this.paid = this.event.paid

        this.date = moment(this.event.date).format('Do MMMM YYYY - hh:mm')

        if (this.$root.$data.gender == 'Female') {
            this.paymentData.amount = "3000"
        }
        if (this.$root.$data.gender == 'Male') {
            this.paymentData.amount = "5000"
        }
        
    },
    methods: {
        back() {
            this.$router.go(-1)
        },
        
        payViaService() {
            if (this.whatsapp.length != 11) {
                this.whatapp_err(); return
            }

            this.load = true

            let form = new FormData()
            form.append('event_id', this.event.event_id)

            this.$http.post('/event/get', form)
            .then(data => {
                let r = data.data
                if (r.success) {
                    if (r.event.total_paid >= r.event.participants) {
                        this.closed(); this.load = false; return
                    }
                    if (this.$root.$data.gender == 'Male') {
                        if (r.event.m >= r.event.male) {
                            this.closed(); this.load = false; return
                        }
                    }
                    if (this.$root.$data.gender == 'Female') {
                        if (r.event.f >= r.event.female) {
                            this.closed(); this.load = false; return
                        }
                    }

                    this.$payWithFlutterwave(this.paymentData);
                }
            })

        },
        makePaymentCallback(response) {
            console.log("Pay", response);

            let form = new FormData()
            form.append('event_id', this.event.event_id)
            form.append('gender', this.$root.$data.gender == 'Male' ? 'm' : 'f')
            form.append('meta', JSON.stringify(response))
            form.append('amount', response.amount)
            form.append('tx_id', response.transaction_id)
            form.append('whatsapp', this.whatsapp)

            this.$http.post('/event/pay', form)
            .then(data => {
                let r = data.data
                if (r.success) {
                    this.paid = true
                    
                    this.load = false
                }
            })
        },
        closedPaymentModal() {
            if (this.paid) {
                this.payment_made()
            } else {
                this.cancelled()
            }
            this.load = false
        },
        generateReference(){
            let date = new Date();
            return date.getTime().toString();
        }
    },
    notifications: {
        whatapp_err: {
            'type': 'warn',
            'title': 'Whatsapp Number',
            'message': 'Please enter your 11 digit whatsapp number'
        },
        closed: {
            'type': 'info',
            'title': 'Opps',
            'message': 'Something went wrong, try again later'
        },
        cancelled: {
            'type': 'warn',
            'title': 'Cancelled',
            'message': 'Payment not completed'
        },
        payment_made: {
            'type': 'success',
            'title': 'Success',
            'message': 'your payment was successful'
        }
    }
}
</script>

<style scoped>

    .event {
        background-color: white;
    }
    .head {
        display: grid;
        grid-template-columns: calc(50% - 20px) 50%;
        align-items: center;
        padding: 15px 15px;
        border-bottom: 1px solid #ddd;
        fill: var(--main);
    }
    .head_title {
        font-weight: 500;
    }

    .main {
        height: calc(100vh - 51px);
        overflow: auto;
    }

    img {
        width: 100%;
    }


    .info {
        padding: 20px;
    }
    .f {
        margin-bottom: 20px;
    }
    .label {
        font-size: 14px;
        color: gray;
    }
    .value {
        font-weight: 500;
        margin-top: 5px;
    }

    input {
        padding: 10px;
        border-radius: 5px;
        border: 1px solid #ddd;
        width: 100%;
        box-sizing: border-box;
        margin-top: 5px;
    }

    .btn {
        background-color: var(--main);
        color: white;
        padding: 10px;
        border-radius: 5px;
        font-weight: 500;
        text-align: center;
        cursor: pointer;
    }
</style>